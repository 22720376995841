<template>
  <div class="text-right my-2">
    <v-btn @click="update()" class="text-capitalize" color="success"
      >Update Geo Data <v-icon>mdi-map</v-icon></v-btn
    >
    <div class="my-2" v-if="updating">
      Completed {{ completed }}/{{ values.length }}
    </div>
    <div v-if="completed == values.length" class="mt-3">
      <v-chip @click="completed = 0" color="info">All Parcels updated</v-chip>
    </div>
  </div>
</template>

<script>
import parcel from '../../mixins/parcel'
export default {
  mixins: [parcel],
  props: {
    values: {
      type: Array
    }
  },
  data() {
    return {
      updating: false,
      completed: 0
    }
  },
  methods: {
    async update() {
      this.updating = true

      for (let i = 0; i < this.values.length; i++) {
        try {
          await this.updateParcelGeoData(this.values[i])
        } catch (err) {
          console.log(err)
        }
        this.completed++
      }

      this.updating = false
    }
  }
}
</script>

<style></style>
