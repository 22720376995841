<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <div v-if="user">
        <v-card outlined max-width="600">
          <v-card-title class="primary--text"> User Detail </v-card-title>
          <v-container>
            <p>Email: {{ user.email }}</p>
            <p>Name: {{ user.first_name }} {{ user.last_name }}</p>
            <p>Last Login: {{ formatDate(user.last_login) }}</p>
          </v-container>
          <v-card-actions>
            <v-btn
              :href="`mailto:${user.email}`"
              target="_blank"
              large
              color="primary"
              >Send Email <v-icon>mdi-email</v-icon></v-btn
            >
          </v-card-actions>
        </v-card>
      </div>
      <div v-else>
        <v-skeleton-loader
          type="card"
          max-width="600"
          class="mx-auto"
        ></v-skeleton-loader>
      </div>
      <h1 class="primary--text title">Parcels</h1>
      <div class="parcels">
        <div>
          <div>
            <update-geo-data
              :values="parcelList.map(e => e.id)"
            ></update-geo-data>
          </div>
          <v-row v-if="parcelList.length">
            <v-col
              sm="6"
              md="4"
              lg="3"
              xl="2"
              cols="12"
              v-for="(parcel, index) in parcelList"
              :key="index"
            >
              <parcel-detail
                @update="updateParcels()"
                :admin="true"
                v-if="parcel"
                :data="parcel"
              ></parcel-detail>
            </v-col>
          </v-row>
          <v-row v-else-if="loading">
            <v-col
              v-for="(i, index) in 4"
              :key="index"
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="2"
            >
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
          </v-row>
          <div class="text-center" v-else>
            <v-alert color="info" text>No Parcels Added Yet.<br /> </v-alert>
          </div>
        </div>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import admin from '@/mixins/admin'
import Moment from 'moment'
import parcel from '@/mixins/parcel'
import ParcelDetail from '@/components/Parcel/Detail'
import UpdateGeoData from '@/components/Admin/UpdateGeoData'
export default {
  mixins: [admin, parcel],
  components: { ParcelDetail, UpdateGeoData },
  data: () => ({
    breadcrumbs: [
      { text: 'Admin', to: '/admin/dashboard', exact: true },
      { text: 'User', to: '/admin/user/id', disabled: true },
      { text: 'Loading', to: '/admin/user/id', disabled: true }
    ],
    user: null,
    parcelList: [],
    loading: true
  }),
  async mounted() {
    await this.updateUser()
    await this.updateParcels()
    this.breadcrumbs[2].text = this.user.email
  },
  methods: {
    async updateUser() {
      let u = await this.getUserDetail(this.$route.params.id)
      this.user = u
      if (!u) {
        this.$router.push('/admin')
      }
    },
    async updateParcels() {
      let p = await this.getParcelList(this.$route.params.id)
      let data = []
      if (!p.empty) {
        p.forEach(e => {
          data.push({ ...e.data(), id: e.id })
        })
      }
      this.parcelList = data
      this.loading = false
    },
    formatDate(ts) {
      let lastLogin = null
      if (typeof ts === 'object') {
        lastLogin = new Date(ts.seconds * 1000)
      } else {
        lastLogin = new Date(ts)
      }
      if (lastLogin == 'Invalid Date') {
        return ''
      }
      return Moment(lastLogin).format('lll')
    }
  }
}
</script>

<style></style>
